@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../src/components/UserCardGridBackground.global.css';


body {
  /* controls the font smoothing, eg: Chrome displays fonts bolder than they actually are */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-900;
}

._sidebar-nav {
  min-height: -webkit-fill-available;
}

.daisy-btn {
  @apply min-h-8 h-9 font-semibold;
  text-transform: none;
}
.daisy-btn span > svg {
  @apply inline-block !-ml-1;
}
.daisy-btn svg:only-child {
  @apply !m-0;
}
/**/
.daisy-btn-sm {
  @apply h-8 px-3 text-xs leading-6;
}
.daisy-btn-sm svg {
  @apply w-4 h-4;
}
.daisy-btn-sm svg:first-child {
  @apply mr-[6px];
}
.daisy-btn-sm svg:last-child {
  @apply ml-[6px];
}
/**/
.daisy-btn-md {
  @apply h-9 px-4 text-sm leading-6;
}
.daisy-btn-md svg {
  @apply w-[18px] h-[18px];
}
.daisy-btn-md svg:first-child {
  @apply mr-2;
}
.daisy-btn-md svg:last-child {
  @apply ml-2;
}
/**/
.daisy-btn-lg {
  @apply h-12 px-4 text-base leading-8;
}
.daisy-btn-lg svg {
  @apply w-6 h-6;
}
.daisy-btn-lg svg:first-child {
  @apply mr-2;
}
.daisy-btn-lg svg:last-child {
  @apply ml-2;
}
/**/
.daisy-btn-xl {
  @apply h-16 px-6 text-lg leading-8;
}
.daisy-btn-xl svg {
  @apply w-6 h-6;
}
.daisy-btn-xl svg:first-child {
  @apply mr-[10px];
}
.daisy-btn-xl svg:last-child {
  @apply ml-[10px];
}
/**/

.daisy-select {
  @apply border-none;
  @apply bg-gray-100;
}

.daisy-select-bordered {
  @apply !border-gray-300;
  @apply !border-solid;
}

.daisy-textarea {
  @apply bg-gray-100;
  @apply border-gray-300;
}

.daisy-input {
  @apply bg-gray-100;
  @apply border-gray-300;
}

.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.tippy-box ._hs-inspector {
  color: initial !important;
}

._sidebar-nav ._user-block [data-tippy-root] {
  @apply !transform-none !left-2 !right-2 !bottom-[58px];
}

._hide-scrollbar::-webkit-scrollbar {
  display: none;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#arcade-button-popover[data-placement^='top'] > #arrow {
  bottom: -6px;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.daisy-table td {
  border-color: #e5e7eb;
}

table._query-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px; /* correct offset on first border spacing if desired */
}
table._query-table td {
  border-width: 1px;
  border-color: #f2f3f5;
  border-top-style: solid;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: solid;
  padding: 10px;
  text-align: center;
}
table._query-table tr.excluded-row td {
  color: #8b8b8b;
}
table._query-table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
table._query-table td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* Hide weird empty box in post-login state */
/* https://github.com/firebase/firebaseui-web/issues/121 */
#get-started .firebaseui-id-page-callback {
  height: 2px;
  overflow: hidden;
  display: block;
  min-height: 2px;
  box-shadow: none;
}

.firebase-emulator-warning {
  display: none;
}
.group-scoped:hover .group-scoped-hover\:h-3 {
  @apply h-3;
}
.group-scoped:hover .group-scoped-hover\:opacity-100 {
  @apply opacity-100;
}

/** CSS For the hotspot target */

@keyframes outerCircleAnimation {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    transform: scale3d(2.3, 2.3, 2.3);
    opacity: 0;
  }
}

.hostpot-target .outer-circle {
  opacity: 0;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: outerCircleAnimation;
  animation-delay: 1.5s;
}

@keyframes innerCircleAnimation {
  0% {
    transform: scale3d(1, 1, 1);
  }
  18% {
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.hostpot-target .inner-circle {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: innerCircleAnimation;
}

.ais-Highlight-highlighted {
  @apply bg-gray-200;
}

/* Hide the default webkit blue cross icon for used for reset/cancel search inputs */
input[type='search']::-webkit-search-cancel-button {
  display: none;
}
